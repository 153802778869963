import * as React from "react";
import './PricingPlan.css';
// import Box from "@mui/joy/Box";
// import Button from "@mui/joy/Button";
import Card from "@mui/joy/Card";
import CardActions from "@mui/joy/CardActions";
import Chip from "@mui/joy/Chip";
import Divider from "@mui/joy/Divider";
import Grid from "@mui/material/Grid2";
// import List from "@mui/joy/List";
// import ListItem from "@mui/joy/ListItem";
// import ListItemDecorator from "@mui/joy/ListItemDecorator";
import Typography from "@mui/joy/Typography";
// import Check from "@mui/icons-material/Check";
// import KeyboardArrowRight from "@mui/icons-material/KeyboardArrowRight";
import { PopupButton } from "react-calendly";
// import Booking from '../../Components/CalendlyBooking/Booking'


export default function PricingCards() {

  return (
    <Grid container spacing={2}>
      
      <Grid size={{ xs: 12, md: 12, lg: 4 }}>
        <Card
          size="lg"
          variant="outlined"
          color="neutral"
          sx={{ backgroundColor: "none"}}
        >
          <Chip className='pricingChip'
            size="sm"
            variant="soft"
            color="primary"
            // textColor="text.tertiary"
            sx={{ borderRadius: "5px"}}
          >
            <Typography color="primary" level="body-xs">
              BASIC
            </Typography>
          </Chip>
          <Typography level="h5" fontWeight={"bold"} textColor={"black"}>
            Initial Consultation
          </Typography>
          <Divider inset="none" />
          <Typography level="body-xs">
          Kickstart your journey with our 15-minute free Initial Consultation. We'll explore your business needs and identify key areas for improvement to set you on the path to success.
          </Typography>
          <Divider inset="none" />
          <CardActions>
            <Typography level="title-lg" sx={{ mr: "auto" }}>
              CAD $0
              <Typography textColor="text.tertiary" sx={{ fontSize: "sm" }}>
                / 15 Mins
              </Typography>
            </Typography>
            
            <PopupButton
              url="https://calendly.com/clovvista-sales/initial-consultation"
              rootElement={document.getElementById("root")}
              text="Book now"
              // textColor='#ff0000'
              className="BookingButton"
            />
          </CardActions>
        </Card>
      </Grid>
      <Grid size={{ xs: 12, md: 12, lg: 4 }}>
        <Card size="lg" variant="outlined"
        sx={{ backgroundColor: "none" }}
        >
          <Chip className='pricingChip'
            size="sm"
            variant="soft"
            color="danger"
            // textColor="text.tertiary"
            sx={{ borderRadius: "5px"}}
          >
            <Typography color="danger" level="body-xs">
              MOST POPULAR
            </Typography>
          </Chip>
          <Typography level="h5" fontWeight={"bold"} textColor={"black"}>
            Target State Planning
          </Typography>
          <Divider inset="none" />
          <Typography level="body-xs">
          Plan for the future with a focused 30-minute session on Target State Planning consultation. We'll help you define your ideal business state and create a roadmap to achieve it.
          </Typography>
          <Divider inset="none" />
          <CardActions>
            <Typography level="title-lg" sx={{ mr: "auto" }}>
              CAD $50{" "}
              <Typography textColor="text.tertiary" sx={{ fontSize: "sm" }}>
                / 30 Mins
              </Typography>
            </Typography>
            <PopupButton
              url="https://calendly.com/clovvista-sales/target-state-planning"
              rootElement={document.getElementById("root")}
              text="Book now"
              // textColor='#ff0000'
              className="BookingButton"
            />
          </CardActions>
        </Card>
      </Grid>
      <Grid size={{ xs: 12, md: 12, lg: 4 }}>
        <Card size="lg" variant="outlined"
        sx={{ backgroundColor: "none" }}
        >
          <Chip className='pricingChip'
            size="sm"
            variant="soft"
            color="success"
            // textColor="text.tertiary"
            sx={{ borderRadius: "5px"}}
          >
            <Typography color="success" level="body-xs">
              RECOMMENDED
            </Typography>
          </Chip>
          <Typography level="h5" fontWeight={"bold"} textColor={"black"}>
            Target State Planning & Solution Architecture
          </Typography>
          <Divider inset="none" />
          <Typography level="body-xs">
          Get strategic with our 1-hour Target State Planning & Solution Architecture consultation. We'll design tailored solutions to optimize your business operations.
          </Typography>
          <Divider inset="none" />
          <CardActions>
            <Typography level="title-lg" sx={{ mr: "auto" }}>
              CAD $100{" "}
              <Typography textColor="text.tertiary" sx={{ fontSize: "sm" }}>
                / 60 mins
              </Typography>
            </Typography>
           <PopupButton
              url="https://calendly.com/clovvista-sales/target-state-planning-solution-architecture"
              rootElement={document.getElementById("root")}
              text="Book now"
              // textColor='#ff0000'
              className="BookingButton"
            />
          </CardActions>
        </Card>
      </Grid>
      {/* </Box> */}
    </Grid>
  );
}
