import './App.css';
import { Fragment } from 'react';
// import HomePage from './Pages/Home/Home'
// import Service1Page from './Pages/Services1/Services1';
// import Service2Page from './Pages/Service2/Service2'
// import Service3Page from './Pages/Service3/Service3'
// import Service4Page from './Pages/Service4/Service4'
import Routing from './Routing/Routing';

function App() {
  return (
    <Fragment>
      <Routing/>
      {/* <Service1Page/> */}
      {/* <Service2Page/> */}
{/* <Service3Page/> */}
{/* <Service4Page/> */}
{/* <HomePage/> */}
    </Fragment>
  );
}

export default App;
