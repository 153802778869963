import React, { Fragment } from "react";
import "./ProjectCards.css";
import Grid from "@mui/material/Grid2";
import { Box } from "@mui/material";
import Card from "@mui/joy/Card";
import CardCover from "@mui/joy/CardCover";
import Typography from "@mui/joy/Typography";
import Divider from "@mui/joy/Divider";
import P1 from "../../Assets/Project.png";
import P2 from "../../Assets/Project2.png";
import P3 from "../../Assets/Project3.png";
import P4 from "../../Assets/Project4.png";

// const projectList =[
//   {
//     id:  1,
//     title: "Project 1",
//     description: "This is a description of project 1",
//   },
//   {
//     id:  2,
//     title: "Project 2",
//     description: "This is a description of project 2",
//   },
//   {
//     id:  2,
//     title: "Project 3",
//     description: "This is a description of project 3",
//   },
//   {
//     id:  3,
//     title: "Project 4",
//     description: "This is a description of project 4",
//   },
// ]

export default function ProjectCards() {
  return (
    <Fragment>
      <Grid container spacing={2} mt={3}>
        {/* <Grid size={{ xs: 12, md: 12, lg: 12 }}>
          <Typography level="body-sm" align="right">
            Learn More 
            <ArrowOutwardIcon fontSize="xs" sx={{marginLeft:'5px'}}/>
          </Typography>
        </Grid> */}
        <Grid size={{ xs: 12, md: 12, lg: 3 }}>
          <Box>
            <Card
              sx={{ width: "100%", height: "30rem", padding: "0px" }}
              className="homeProjectContainer"
            >
              <CardCover className="imgzoom">
                {/* <Box className="imageContainer"> */}
                <img src={P1} loading="lazy" alt="Project 1"/>
                {/* </Box> */}
              </CardCover>
              <Box className="projectCardHorizontal">
                <Typography
                  level="h3"
                  fontWeight={"bold"}
                  sx={{ color: "black" }}
                >
                  Food Service Industry
                </Typography>
                <Divider
                  sx={{
                    backgroundColor: "white",
                    marginTop: "10px",
                    marginBottom: "10px",
                  }}
                />
                <Typography level="body-xs" sx={{ color: "black" }}>
                This project involved creating and managing Virtual Machines on Azure, 
                setting up UAT and production environments, and syncing the on-premises 
                Active Directory with Azure AD for seamless single sign-on (SSO). Additionally, 
                the deployment of the client’s machine was successfully completed.
                </Typography>
              </Box>
            </Card>
          </Box>
        </Grid>
        <Grid size={{ xs: 12, md: 12, lg: 3 }}>
          <Box>
            <Card
              sx={{ width: "100%", height: "30rem", padding: "0px" }}
              className="homeProjectContainer"
            >
              <CardCover>
                <img src={P2} loading="lazy" alt="Project 2" />
              </CardCover>
              <Box className="projectCardHorizontal">
                <Typography
                  level="h3"
                  fontWeight={"bold"}
                  sx={{ color: "black" }}
                >
                  Banking Sector
                </Typography>
                <Divider
                  sx={{
                    backgroundColor: "white",
                    marginTop: "10px",
                    marginBottom: "10px",
                  }}
                />
                <Typography level="body-xs" sx={{ color: "black" }}>
                This project provided cloud governance guidance and a detailed 
                roadmap to ensure a smooth cloud migration for Jamaica’s largest 
                bank, preventing outages of critical applications. Key tasks included 
                inventory gathering, migration runbooks, and risk analysis for seamless 
                decommissioning.
                </Typography>
              </Box>
            </Card>
          </Box>
        </Grid>
        <Grid size={{ xs: 12, md: 12, lg: 3 }}>
          <Box>
            <Card
              sx={{ width: "100%", height: "30rem", padding: "0px" }}
              className="homeProjectContainer"
            >
              <CardCover>
                <img src={P3} loading="lazy" alt="Project 3" />
              </CardCover>
              <Box className="projectCardHorizontal">
                <Typography
                  level="h3"
                  fontWeight={"bold"}
                  sx={{ color: "black" }}
                >
                 Automobile Sector
                </Typography>
                <Divider
                  sx={{
                    backgroundColor: "white",
                    marginTop: "10px",
                    marginBottom: "10px",
                  }}
                />
                <Typography level="body-xs" sx={{ color: "black" }}>
                This project ensured OWASP compliance, implemented secure 
                code pipelines with Veracode scans, and managed key client deliverables, 
                including QA and knowledge transfer.
                </Typography>
              </Box>
            </Card>
          </Box>
        </Grid>
        <Grid size={{ xs: 12, md: 12, lg: 3 }}>
          <Box>
            <Card
              elevation={"5"}
              sx={{ width: "100%", height: "30rem", padding: "0px" }}
              className="homeProjectContainer"
            >
              <CardCover>
                <img src={P4} loading="lazy" alt="Project 4" />
              </CardCover>
              <Box className="projectCardHorizontal">
                <Typography
                  level="h3"
                  fontWeight={"bold"}
                  sx={{ color: "black" }}
                >
                  Public Sector 
                </Typography>
                <Divider
                  sx={{
                    backgroundColor: "white",
                    marginTop: "10px",
                    marginBottom: "10px",
                  }}
                />
                <Typography level="body-xs" sx={{ color: "black" }}>
                This project migrated the client’s data from an on-premises IBM Netezza 
                infrastructure to Azure Synapse, utilizing Azure Analysis Services and 
                Azure DevOps for pipeline management. Tabular models and Power BI dashboards 
                were developed for data visualization, along with migrating on-premises AD users 
                to Azure AD.
                </Typography>
              </Box>
            </Card>
          </Box>
        </Grid>
      </Grid>
    </Fragment>
  );
}
