import React, { Fragment } from "react";
import "./ServiceCard.css";
import Grid from "@mui/material/Grid2";
import { Box, Typography } from "@mui/material";
import devImg from "../../Assets/development.png";
import designImg from "../../Assets/design.png";
import networkingImg from "../../Assets/networking.png";
import cloudImg from "../../Assets/HybridCloud-removebg-preview.png";
import ArrowOutwardIcon from "@mui/icons-material/ArrowOutward";
import { Fade   } from "react-awesome-reveal";
import { useNavigate } from "react-router-dom";

export default function ServiceCard() {

  const navigate = useNavigate()

  return (
    <Fragment>
      <Grid container spacing={4} mt={5}>
        
        <Grid container columnSpacing={4} size={{ xs: 12, md: 12, lg: 7 }}>   {/** Left Side Container */}

        <Fade triggerOnce='true'> 
          <Grid size={{ xs: 12, md: 12 }}>
            <Box className="cardContainer">
              <Box className="cardHeadingContainer">
                <Box className="cardHeadingCircleContainer">
                  <Box className="cardHeadingCircle"></Box>
                  <Box className="cardHeadingBorder"></Box>
                </Box>
                <Box className="cardHeadingNumber">
                  <Typography
                    align="right"
                    fontWeight={"bold"}
                    variant="h5"
                    className="headingNumber"
                  >
                    01
                  </Typography>
                </Box>
              </Box>
              <Box sx={{ marginTop: "0px" }}>
                <Grid container>
                  <Grid size={{ xs: 12, md: 9 }}>
                    <Typography
                      variant="h6"
                      fontWeight={"bold"}
                      fontFamily={"open sans"}
                      my={2}
                      color="#2a6485"
                    >
                      Web Development
                    </Typography>
                    <Typography variant="body2" fontFamily={"open sans"}>
                      We specializes in creating cutting-edge web
                      development solutions that propel your organization ahead.
                      Our professional developers employ advanced technology to
                      construct visually appealing, user-friendly, and highly
                      functioning websites. 
                    </Typography>
                    <Grid size={{ xs: 12, md: 12, lg: 12 }} mt={2}>
                    <Typography className="serviceLearnMoreText" variant="caption" align="left" color="black" onClick={() => { navigate('web-development') }}>
                      Learn More 
                      <ArrowOutwardIcon fontSize="xs" sx={{marginLeft:'5px'}}/>
                    </Typography>
                  </Grid> 
                  </Grid>
                  <Grid size={{ xs: 12, md: 3 }} sx={{display:'flex', alignItems:'center'}}>
                    {/* <img src={devImg} height="150" width="150" /> */}
                    <img src={devImg} loading="lazy" class="img-fluid" alt="web"/>
                  </Grid>
                  
                </Grid>
              </Box>
            </Box>
          </Grid> 
          <Grid size={{ xs: 12, md: 12 }}>
            <Box className="cardContainer">
              <Box className="cardHeadingContainer">
                <Box className="cardHeadingCircleContainer">
                  <Box className="cardHeadingCircle"></Box>
                  <Box className="cardHeadingBorder"></Box>
                </Box>
                <Box className="cardHeadingNumber">
                  <Typography
                    align="right"
                    fontWeight={"bold"}
                    variant="h5"
                    className="headingNumber"
                  >
                    02
                  </Typography>
                </Box>
              </Box>
              <Box sx={{ marginTop: "0px" }}>
                <Grid container>
                  <Grid size={{ xs: 12, md: 9 }}>
                    <Typography
                      variant="h6"
                      fontWeight={"bold"}
                      fontFamily={"open sans"}
                      my={2}
                      color="#2a6485"
                    >
                      UI/UX Designing
                    </Typography>
                    <Typography variant="body2" fontFamily={"open sans"}>
                      We specializes in designing visually appealing and
                      user-friendly online and mobile apps. Our brilliant
                      designers combine creativity and technological knowledge
                      to create designs that look amazing but also improve user
                      experience and engagement. 
                    </Typography>
                    <Grid size={{ xs: 12, md: 12, lg: 12 }} mt={2}>
                    <Typography className="serviceLearnMoreText" variant="caption" align="left" color="black" onClick={() => { navigate('uiux-designing') }}>
                      Learn More 
                      <ArrowOutwardIcon fontSize="xs" sx={{marginLeft:'5px'}}/>
                    </Typography>
                  </Grid> 
                  </Grid>
                  <Grid size={{ xs: 12, md: 3 }} sx={{display:'flex', alignItems:'center'}}>
                    {/* <img src={designImg} height="150" width="150" /> */}
                    <img src={designImg} loading="lazy" class="img-fluid" alt="design"/>
                  </Grid>
                  
                </Grid>
              </Box>
            </Box>
          </Grid>
        </Fade>

        </Grid>

        <Grid size={{ xs: 12, md: 12, lg: 5  }}>  {/** Right Side Container */}
          <Fade triggerOnce='true'>
          <Grid size={{ xs: 12, md: 12 }}>
            <Box className="cardContainer">
              <Box className="cardHeadingContainer">
                <Box className="cardHeadingCircleContainer">
                  <Box className="cardHeadingCircle"></Box>
                  <Box className="cardHeadingBorder"></Box>
                </Box>
                <Box className="cardHeadingNumber">
                  <Typography
                    align="right"
                    fontWeight={"bold"}
                    variant="h5"
                    className="headingNumber"
                  >
                    03
                  </Typography>
                </Box>
              </Box>
              <Box sx={{ marginTop: "0px" }}>
                <Grid container>
                  <Grid size={{ xs: 12, md: 12 }}>
                    <Typography
                      variant="h6"
                      fontWeight={"bold"}
                      fontFamily={"open sans"}
                      my={2}
                      color="#2a6485"
                    >
                      Cloud Infrastructure & Solutions
                    </Typography>
                    <Typography variant="body2" fontFamily={"open sans"}>
                      ClovVista specializes in DevOps and solution architecture
                      services to optimize cloud operations and enhance
                      productivity. Our team uses industry best practices and
                      cutting-edge solutions to automate operations and ensure
                      reliable, scalable cloud infrastructure.
                    </Typography>
                  </Grid>
                  <Grid size={{ xs: 12, md: 12, lg: 12 }} mt={2}>
                    <Typography className="serviceLearnMoreText" variant="caption" align="left" color="black" onClick={() => { navigate('cloud-solution') }}>
                      Learn More 
                      <ArrowOutwardIcon fontSize="xs" sx={{marginLeft:'5px'}}/>
                    </Typography>
                  </Grid> 
                  <Grid size={{ xs: 12, md: 12 }} >
                    {/* <img src={cloudImg} height="250" width="450" /> */}
                    <img src={cloudImg} loading="lazy" class="img-fluid float-start" alt="cloud"/>
                  </Grid>
                  
                </Grid>
              </Box>
            </Box>
          </Grid>
          </Fade>
        </Grid>

        <Grid size={{ xs: 12, md: 12 }}>      {/** Bottom Side Container */}
          <Fade triggerOnce='true'>
          <Box className="cardContainer4">
            <Box className="cardLastHeadingContainer">
              <Box className="cardLastHeadingCircleContainer">
                <Box className="cardLastHeadingCircle"></Box>
                <Box className="cardLastHeadingBorder"></Box>
              </Box>
              <Box className="cardLastHeadingNumber">
                <Typography
                  align="right"
                  fontWeight={"bold"}
                  variant="h5"
                  className="headingNumber"
                >
                  04
                </Typography>
              </Box>
            </Box>
            <Box sx={{ marginTop: "0px" }}>
            <Grid container>
            <Grid size={{ xs: 12, md: 10 }}>
                <Typography
                  variant="h6"
                  fontWeight={"bold"}
                  fontFamily={"open sans"}
                  mx={1}
                  color="#2a6485"
                >
                  IT Network & Support
                </Typography>
                <Typography variant="body2" fontFamily={"open sans"} mx={1}>
                  ClovVista specializes in network and system administration
                  services to maintain your IT infrastructure's security,
                  dependability, and performance. Our trained specialists offer
                  assistance for network design, installation, maintenance,
                  troubleshooting, and protecting sensitive data and equipment.
                </Typography>
                <Grid size={{ xs: 12, md: 12, lg: 12 }} mt={2} mx={1}>
                    <Typography className="serviceLearnMoreText" variant="caption" align="left" color="black" onClick={() => { navigate('network-solution') }}>
                      Learn More 
                      <ArrowOutwardIcon fontSize="xs" sx={{marginLeft:'5px'}}/>
                    </Typography>
                </Grid> 
              </Grid>
              <Grid size={{ xs: 12, md: 2 }}>
                <img src={networkingImg} loading="lazy" class="img-fluid float-start" alt="network"/>
              </Grid>
             
            </Grid>
            </Box>
          </Box>
          </Fade>
        </Grid>

      </Grid>
    </Fragment>
  );
}
