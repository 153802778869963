import React from "react";
import { Routes, Route } from "react-router";
import HomePage from '../Pages/Home/Home'
import ServicePage from '../Pages/Services1/Services1'
import Service2Page from '../Pages/Service2/Service2'
import Service3Page from '../Pages/Service3/Service3'
import Service4Page from '../Pages/Service4/Service4'
// import Home from "../Pages/Home/Home";

export default function Routing() {

  return (
    <>
      <Routes>
        <Route exact path="/" element={<HomePage />}/>
        <Route exact path="/web-development" element={<ServicePage />} />
        <Route path="/uiux-designing" element={<Service2Page />} />
        <Route path="/cloud-solution" element={<Service3Page />} />
        <Route path="/network-solution" element={<Service4Page />} />
        <Route path="#services" element={<HomePage />}/>
          {/* <Route path="services" element={<HomePage />}/> */}
        {/* </Route> */}
      </Routes>
    </>
  );
}
